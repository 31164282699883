import axios from "axios";

export async function doGetSearch(url, data) {
  let searchUrl = localStorage.getItem("searchUrl");
  const hostName = window.location.hostname;
  if (!searchUrl) {
    const conf = await fetch("/config.json").then((r) => r.json());
    searchUrl = `${conf.SEARCH_APP_DOMAIN}`;
    localStorage.setItem("searchUrl", searchUrl);
  }
  const token =
    localStorage.getItem(`${hostName}-token`) ||
    sessionStorage.getItem("token") ||
    "";
  return axios
    .request({
      method: "get",
      url: `${searchUrl}${url}`,
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if ([401, "401"].includes(error.response.status)) {
        return error.response;
      } else {
        return error.toJSON();
      }
    });
}

export async function doGet(url) {
  const hostName = window.location.hostname;
  let hostUrl =
    localStorage.getItem(`${hostName}-hostUrl`) ||
    localStorage.getItem(`hostUrl`);
  if (!hostUrl) {
    const conf = await fetch("/config.json").then((r) => r.json());
    hostUrl = `${conf.REACT_APP_DOMAIN}`;
    localStorage.setItem(`${hostName}-hostUrl`, hostUrl);
  }
  const token =
    localStorage.getItem(`${hostName}-token`) ||
    sessionStorage.getItem("token") ||
    "";
  return axios
    .request({
      method: "get",
      url: `${hostUrl}${url}`,
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if ([401, "401"].includes(error.response.status)) {
        return error.response;
      } else {
        return error.toJSON();
      }
    });
}
export async function doPost(url, data) {
  const hostName = window.location.hostname;
  let hostUrl =
    localStorage.getItem(`${hostName}-hostUrl`) ||
    localStorage.getItem(`hostUrl`);
  if (!hostUrl) {
    const conf = await fetch("/config.json").then((r) => r.json());
    hostUrl = `${conf.REACT_APP_DOMAIN}`;
    localStorage.setItem(`${hostName}-hostUrl`, hostUrl);
  }
  const token =
    localStorage.getItem(`${hostName}-token`) ||
    sessionStorage.getItem("token") ||
    "";
  return axios
    .request({
      method: "post",
      url: `${hostUrl}${url}`,
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if ([401, "401"].includes(error.response.status)) {
        return error.response;
      } else {
        return error.toJSON();
      }
    });
}
