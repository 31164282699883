import React from "react";
import Loadable from "react-loadable";
import { Route, Switch, withRouter } from "react-router-dom";
import withSessionContext from "HOC/withSessionContext";
// import NotificationRoot from "components/NotificationRoot";
import queryString from "query-string";
import SpinnerRoot from "components/SpinnerRoot";
import NotFound from "components/NotFound";
import Loading from "components/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
const AsyncPrivateRoutes = Loadable({
  loader: () => import("containers/Routes/PrivateRoutes"),
  loading: Loading,
});

const AsyncPublicRoutes = Loadable({
  loader: () => import("containers/Routes/PublicRoutes"),
  loading: Loading,
});

const homeBase = "/authenticated/home";
const glossaryBase = "/authenticated/glossary";
const workspaceBase = "/authenticated/workspace";
const assignmentsBase = "/authenticated/assignments";
const contentBase = "/authenticated/content";
const mygroupsBase = "/authenticated/mygroups";
const studentDashboard = "/authenticated/studentDashboard";
const admin = "/authenticated/admin";
const manageUsers = "/authenticated/admin/manageUsers";
const profileSettings = "/authenticated/profileSettings";
const externalTools = "/authenticated/admin/externalTools";
const help = "/authenticated/help";
const feedbackform = "/authenticated/feedbackform";
const quizsmart = "/authenticated/quizsmart";

export const routePaths = {
  home: {
    dashboard: `${homeBase}/dashboard`,
    teacherDashboardLearning: `${homeBase}/teacherDashboardLearning`,
    corelationDocument: `${homeBase}/get_citation_data`,
    get_grades_and_standard: `${homeBase}/get_grades_and_standard`,
  },
  assignments: {
    home: `${assignmentsBase}/`,
    reportHome: `${assignmentsBase}/reportHome`,
  },
  help: {
    home: `${help}/home`,
    subHelp: `${help}/subhelp`,
    content: `${help}/content`,
  },
  ssoLogin: {
    getSchool: "/authenticated/selectSchool",
  },
  glossary: {
    home: `${glossaryBase}/home`,
    subject: `${glossaryBase}/subject`,
    facts: `${glossaryBase}/facts`,
    fillIn: `${glossaryBase}/fillIn`,
  },
  content: {
    contentHome: `${contentBase}/contentHome`,
    previewResource: `${contentBase}/previewResource`,
    assessment: `${contentBase}/assessment`,
    printQuiz: `${contentBase}/printQuiz`,
  },
  workspace: {
    lessonPlayer: `${workspaceBase}/lessonPlayer`,
    workspaceHome: `${workspaceBase}/workspaceHome`,
    addNewLesson: `${workspaceBase}/workspaceHome/addnewlesson`,
  },
  studentDashboard: {
    home: `${studentDashboard}/studentDashboard`,
    studentLessonPlayer: `${studentDashboard}/studentLessonPlayer`,
    viewAllAssignment: `${studentDashboard}/viewAllAssignment`,
    resourcesView: `${studentDashboard}/resourcesView`,
    studentQuiz: `${studentDashboard}/studentQuiz`,
    content: `${studentDashboard}/contentlibrary`,
    printQuiz: `${studentDashboard}/printQuiz`,
    StudentLearnosityView: `${studentDashboard}/StudentLearnosityView`,
    lessonPlayerPage: `${studentDashboard}/lessonPlayerPage`,
    report: `${studentDashboard}/report`,
  },
  admin: {
    home: `${admin}/adminHome`,
    managePermision: `${admin}/managePermision`,
    manageUsers: {
      home: `${manageUsers}`,
      search: `${manageUsers}/search`,
      user: `${manageUsers}/user`,
      add: `${manageUsers}/add`,
      district: `${manageUsers}/district`,
      school: `${manageUsers}/school`,
      role: `${manageUsers}/role`,
      bulkupdate: `${manageUsers}/bulkupdate`,
      grade: `${manageUsers}/grade`,
      subject: `${manageUsers}/subject`,
      importUsers: `${manageUsers}/importUsers`,
      askEdusmart: `${manageUsers}/askEdusmart`,
      updateUserId: `${manageUsers}/updateUserId`,
      batchActivation: `${manageUsers}/batchActivation`,
      importMygroup: `${manageUsers}/importMygroup`,
      resetPassword: `${manageUsers}/resetPassword`,
      samlIdentity: `${manageUsers}/samlIdentity`,
      ltiConsumer: `${manageUsers}/ltiConsumer`,
      oneRoster: `${manageUsers}/oneRoster`,
      oneRosterImport: `${manageUsers}/oneRosterImport`,
      oneRosterImportApi: `${manageUsers}/oneRosterImportApi`,
      oneRosterImportStatus: `${manageUsers}/oneRosterImportStatus`,
      cleverintegration: `${manageUsers}/cleverintegration`,
      transferMygroup: `${manageUsers}/transferMygroup`,
      cleverSecureSync: `${manageUsers}/cleverSecureSync`,
      statusOfCleverSecureSync: `${manageUsers}/statusOfCleverSecureSync`,
      userNotification: `${manageUsers}/userNotification`,
      classLinkintegration: `${manageUsers}/classLinkintegration`,
      GoogleClassroom: `${manageUsers}/GoogleClassroom`,
    },
    externalTools: {
      saml: `${externalTools}/saml`,
    },

    // manageUsers: `${admin}/manageUsers`,
    contentSetup: `${admin}/contentSetup`,
    authoringTool: `${admin}/authoringTool`,
    permissionList: `${admin}/permissionList`,
    assignNewPermission: `${admin}/assignNewPermission`,
    moduleAssignment: `${admin}/moduleAssignment`,
    questionAnswer: `${admin}/questionAnswer`,
    viewQuestionAnswer: `${admin}/viewQuestionAnswer`,
    assessmentQuestionAnswer: `${admin}/assessmentQuestionAnswer`,
    viewAssessmentQuestionAnswer: `${admin}/viewAssessmentQuestionAnswer`,
    prebuiltAssignResource: `${admin}/prebuiltAssignResource`,

    usageReport: {
      home: `${admin}/usageReport/home`,
      usageAssignmentReport: `${admin}/usageReport/usageAssignmentReport`,
      dataExport: `${admin}/usageReport/dataExport`,
      search: `${admin}/usageReport/search`,
      usageReportAnalyticalSummary: `${admin}/usageReport/summary`,
      usageReportSummary: `${admin}/usageReport/usageSummary`,
      usageReportAssignmentSummary: `${admin}/usageReport/assignmentSummary`,
      itemAnalysisDistrictReport: `${admin}/usageReport/itemAnalysisReport/district`,
      usageReportDownload: `${admin}/usageReport/usageReportDownload`,
      itemAnalysisSchoolReport: `${admin}/usageReport/itemAnalysisReport/school`,
      itemAnalysisGroupReport: `${admin}/usageReport/itemAnalysisReport/group`,
      itemAnalysisStudentReport: `${admin}/usageReport/itemAnalysisReport/student`,
      itemAnalysisStateReport: `${admin}/usageReport/itemAnalysisReport/state`,
    },
  },
  profileSettings: {
    home: `${profileSettings}/home`,
    resetPassword: `${profileSettings}/resetPassword`,
    profile: `${profileSettings}/profile`,
    uploadPhoto: `${profileSettings}/uploadPhoto`,
  },
  mygroups: {
    mygroupsHome: `${mygroupsBase}/mygroupsHome`,
    viewGroups: `${mygroupsBase}/ViewGroups`,
    trackAssessments: `${mygroupsBase}/trackAssessments`,
    readerReport: `${mygroupsBase}/readerReport`,
    htmlReaderReport: `${mygroupsBase}/htmlReaderReport`,
    classReport: `${mygroupsBase}/classReport`,
    studentProgress: `${mygroupsBase}/studentProgress`,
    addNewClass: `${mygroupsBase}/add`,
    updateClass: `${mygroupsBase}/update`,
    assignmentResourceDetails: `${mygroupsBase}/assignmentResourceDetails`,
    printLabels: `${mygroupsBase}/printLabels`,
    groupAssignmentReport: `${mygroupsBase}/groupAssignmentReport`,
    studentAssignmentReport: `${mygroupsBase}/studentAssignmentReport`,
    assignmentProgress: `${mygroupsBase}/assignmentProgress`,
    studentAssignmentDetails: `${mygroupsBase}/studentAssignmentDetails`,
    assessmentStatus: `${mygroupsBase}/assessmentStatus`,
    groupAssementReport: `${mygroupsBase}/groupAssementReport`,
    studentAssementReport: `${mygroupsBase}/studentAssementReport`,
    groupAssementReportView: `${mygroupsBase}/groupAssementReportView`,
    itemWiseReport: `${mygroupsBase}/itemWiseReport`,
    studentAssementReportView: `${mygroupsBase}/studentAssementReportView`,
    studentReportView: `${mygroupsBase}/studentReportView`,
    studentLearnoReportView: `${mygroupsBase}/studentLearnoReportView`,
    viewReport: `${mygroupsBase}/viewReport`,
    addStudents: `${mygroupsBase}/AddStudents`,
    progressMonitoringGroupReport: `${mygroupsBase}/progressMonitoringGroupReport`,
    progressMonitoringStudentReport: `${mygroupsBase}/progressMonitoringStudentReport`,
    base: `${mygroupsBase}`,
  },

  feedbackForm: {
    home: `${feedbackform}`,
  },
  quizsmart: {
    base: `${quizsmart}`,
    studentRegistraion: `${quizsmart}/studentRegistration`,
    studentQuestion: `${quizsmart}/studentQuestion`,
    teacherReportCard: `${quizsmart}/teacherReportCard`,
    todaysWinner: `${quizsmart}/todaysWinner`,
    teacherExitTicket: `${quizsmart}/teacherExitTicket`,
    reportCard: `${quizsmart}/reportCard`,
  },
};

class Routes extends React.Component {
  componentDidMount() {
    this.props.history.listen((location, action) => {
      //do any action if needed
    });
    const parsed = queryString.parse(window.location.search);
    const hostName = window.location.hostname;
    const isLoginAsUser = localStorage.getItem("isLoginAsUser");
    const sessionState = JSON.parse(localStorage.getItem(`session-state`));
    const userId = sessionState?.userData?.userPuid;
    const serverLStore = localStorage.getItem("server");
    let route_path = "/";
    if (parsed?.server === "proc") {
      route_path = serverLStore ? serverLStore : "/?server=proc";
      if (parsed.path) {
        if (
          userId &&
          (localStorage.getItem(`${hostName}-token`) || isLoginAsUser)
        ) {
          route_path = `${parsed.path}?server=proc`;
        } else {
          route_path = `?server=proc&path=${parsed.path}`;
        }
      }
      route_path = route_path.includes("undefined") ? "" : route_path;
      route_path.charAt(0) === "/"
        ? (route_path = route_path)
        : (route_path = "/" + route_path);
      localStorage.setItem("server", route_path);
      this.props.history.push(route_path);
    } else {
      localStorage.setItem("server", "");
    }
  }
  render() {
    return (
      <div style={{ height: "100%" }}>
        <SpinnerRoot />
        <Switch>
          <Route path="/authenticated" component={AsyncPrivateRoutes} />
          <Route path="/" component={AsyncPublicRoutes} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(withSessionContext(Routes));
