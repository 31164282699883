import * as actions from "./actionType";

export const fetchAssignmentData = (payload) => ({
  type: actions.FETCH_ASSIGNMENT_DATA,
  payload,
});

export const fetchAssignmentDataSuccess = (payload) => ({
  type: actions.FETCH_ASSIGNMENT_DATA_SUCCESS,
  payload,
});
export const getAssignedGroups = (payload) => ({
  type: actions.GET_ASSIGNED_GROUPS,
  payload,
});
export const getAssignedGroupsSuccess = (payload) => ({
  type: actions.GET_ASSIGNED_GROUPS_SUCCESS,
  payload,
});
export const getAssignmentResultData = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESULT,
  payload,
});
export const getAssignmentResultSuccess = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESULT_SUCCESS,
  payload,
});
export const getAssignmentResultAndGroups = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESULT_AND_GROUPS,
  payload,
});

