import React from "react";
import withSessionContext from "HOC/withSessionContext";
import { userTypes } from "contexts/session";
import { routePaths } from "../../containers/Routes/Routes";
const NotFound = (props) => {
  const goBack = () => {
    if (
      userTypes[props.sessionContext.userData.role] &&
      userTypes[props.sessionContext.userData.role] == "STUDENT"
    ) {
      props.history.push(routePaths.studentDashboard.home + "/0");
    } else {
      props.history.push(routePaths.content.contentHome);
    }
  };
  return (
    <div className="error-wrapper">
      <div className="error-message">
        <div className="error-message__code">
          <h1>404</h1>
          <div className="subtitle">Not Found</div>
          <a href="#" onClick={goBack}>
            <i className="icon-arrow-back-outline"></i> Back to Home
          </a>
        </div>

        <div className="error-message__extras">
          <div className="error-message__pivot">
            <div className="error-message__extras-c"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-s"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-c"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-s"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-c"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-s"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSessionContext(NotFound);
