import * as actions from "../actions/actionType";

const initialState = {
  assignmentListData: null,
  dateRange: {
    fromDate: null,
    toDate: null,
  },
  assignedGroups: null,
  assignmentResult: null,
};

export const assignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ASSIGNMENT_DATA_SUCCESS:
      return {
        ...state,
        assignmentListData: action.payload,
      };
    case actions.GET_ASSIGNED_GROUPS_SUCCESS:
      return {
        ...state,
        assignedGroups: action.payload,
      };
    case actions.GET_ASSIGNMENT_RESULT_SUCCESS:
      return {
        ...state,
        assignmentResult: action.payload,
      };
    default:
      return state;
  }
};
