import * as React from "react";
import Loading from "components/Loading";
import withSessionContext from "HOC/withSessionContext";
import { useSelector } from "react-redux";

const SpinnerRoot = (props) => {
  const spinner = useSelector((state) => state?.auth?.spinner) || null;
  let spinValue = +props.sessionContext.spinner || spinner;
  return (
    spinValue > 0 && (
      <div className="page-loader-wrapper">
        <div className="page-loader">
          <span></span>
        </div>
      </div>
    )
  );
};

export default withSessionContext(SpinnerRoot);
