export const url = {
  content: {
    getItems: `/v2/content/get_items`,
    getUnits: `/v2/content/get_units`,
    updateDefaultFilter: `/v2/content/updateDefaultFilter`,
    getStarSmartAndRcTests: `/v2/content/getStarSmart_and_RcTests`,
    getEngineeringDesingItems: `/v2/content/get_engineeringDesing_Items`,
  },
  assignment: {
    getAssignmentData: `/v2/workspace/get_group_assignments`,
    getAssignmentResult: `/v2/workspace/get_assignment_result`,
    getAssignedGroups: `/v2/workspace/get_assigned_groups`,
    getLessonAccessData: `/v2/content/generate_code`,
  },
  dashboard: {
    corelationDocumentData: `/v2/Citation/get_citation_data`,
    get_grades_and_standard: `/v2/Citation/get_grades_and_standard`,
    get_item_breakout_list: `/v2/Citation/get_item_breakout_list`,
    studentDashboard: `/v2/studentDashboard/load_student_dashboard`,
    viewAllAssignments: `/v2/studentDashboard/view_all_assignments`,
    getAssignmentResponses: `/Dashboard/get_assignment_responses`,
    getStudentsTracking: `/v2/studentDashboard/get_otherstudents_tracking`,
    trackLesson: `/Assignment/track_lesson`,
    trackLessonResource: `/Assignment/track_lesson_resource`,
    getItemPrintQuizData: `/ContentTracking/get_printable_response`,
    quizDetails: `/quiz/get_quiz_details`,
    startQuiz: `/quiz/start_quiz`,
    submitQuiz: `/quiz/submit_quiz_response`,
    startLearnosityAssessment: `/Learnosity/start_learnosity_assessment`,
    submitLearnosityAssessment: `/Learnosity/submit_learnosity_assessment`,
    assignmentFromLessonUrl: `/Assignment/assign_assignment_from_lessonUrl`,
    checkResourceCompletion: `/lesson/check_resource_completion`,
  },
  search: {
    search: `/search`,
  },
  preview: {
    getPreviewItemAccessed: `/PreviewDashboard/get_preview_items_accessed`,
  },
  mylibrary: {
    getMyLibraryResources: `/v2/mylibrary/Get_MyLibrary_resources`,
  },
  assessment: {
    assessmentOverview: `/assessment/assessment_overview`,
    startAssessment: `/assessment/start_assessment`,
    submitAssessment: `/assessment/submit_assessment_response`,
    startLearnosityAssessment: `/Learnosity/start_learnosity_assessment`,
  },
};
