import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import { contentLibraryReducer } from "../containers/ContentLibrary/reducers/contentLibrary";
import { StudentdashboardReducer } from "../containers/NewStudentDashboard/reducers/StudentdashboardReducer";
import { assignmentReducer } from "../containers/Assignments/reducers/assignment";
import { authReducer } from "./authReducer";
import { watcherSaga } from "../redux/rootSaga";
import { saveLocalState, loadLocalState } from "../utils/localStorage";
import throttle from "lodash/throttle";

const sagaMiddleware = createSagaMiddleware();
const persistData = loadLocalState();
const reducer = combineReducers({
  contentLibrary: contentLibraryReducer,
  studentDashboard: StudentdashboardReducer,
  assignment: assignmentReducer,
  auth: authReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  persistData,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

store.subscribe(
  throttle(() => {
    saveLocalState({
      contentLibrary: store.getState().contentLibrary,
      studentDashboard: store.getState().studentDashboard,
      assignment: store.getState().assignment,
    });
  }, 600)
);
sagaMiddleware.run(watcherSaga);
export default store;
