import React, { useEffect } from "react";
import withSessionContext from "HOC/withSessionContext";

function NoInternet() {
  // useEffect(() => {
  //   this.props.sessionContext.stopSpinner();
  // }, []);
  return (
    <div className="error-wrapper">
      <div className="error-message">
        <div className="error-message__code">
          <h1></h1>
          <div className="subtitle">
            Not able to connect to internet. Please check your internet
            connection or try later
          </div>
          <a href="#">{/* <i className="#"></i> */}</a>
          <div></div>
        </div>
        <div className="error-message__extras">
          <div className="error-message__pivot">
            <div className="error-message__extras-c"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-s"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-c"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-s"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-c"></div>
          </div>
          <div className="error-message__pivot">
            <div className="error-message__extras-s"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withSessionContext(NoInternet);
