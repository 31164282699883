import {
  START_SPINNER,
  STOP_SPINNER,
  SHOW_POPUP,
  HIDE_POPUP,
} from "./authActions";

const initialState = {
  spinner: false,
  showPopUp: null,
};
export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_SPINNER:
      return { ...state, spinner: true };
    case STOP_SPINNER:
      return { ...state, spinner: false };
    case SHOW_POPUP:
      return { ...state, showPopUp: action.payload };
    case HIDE_POPUP:
      return { ...state, showPopUp: null };
    default:
      return state;
  }
};
