import { all, call } from "redux-saga/effects";
import { contentLibrarySaga } from "../containers/ContentLibrary/saga/contentLibrarySaga";
import { StudentdashboardSaga } from "../containers/NewStudentDashboard/saga/StudentdashboardSaga";
import { assignmentSaga } from "../containers/Assignments/saga/assignmentSaga";

export function* watcherSaga() {
  yield all([
    call(contentLibrarySaga),
    call(StudentdashboardSaga),
    call(assignmentSaga),
  ]);
}
