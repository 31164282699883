export const loadLocalState = () => {
  try {
    const contentLibraryState = localStorage.getItem("contentLibrary");
    if (contentLibraryState === null) return undefined;
    return JSON.parse(contentLibraryState);
  } catch (error) {
    return undefined;
  }
};

export const saveLocalState = (state) => {
  try {
    const contentLibraryState = JSON.stringify(state);
    localStorage.setItem("contentLibrary", contentLibraryState);
  } catch (err) {
    console.log(err);
  }
};
