export const START_SPINNER = "START_SPINNER";
export const STOP_SPINNER = "STOP_SPINNER";
export const SHOW_POPUP = "SHOW_POPUP";
export const HIDE_POPUP = "HIDE_POPUP";

export const startSpinner = () => ({
  type: START_SPINNER,
});
export const stopSpinner = () => ({
  type: STOP_SPINNER,
});
export const showPopUp = (payload) => ({
  type: SHOW_POPUP,
  payload: payload,
});
export const hidePopUp = () => ({
  type: HIDE_POPUP,
});
