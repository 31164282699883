import * as actions from "./actionTypes";

export const getStudentDashboardData = (payload) => ({
  type: actions.GET_STUDENT_DASHBOARD,
  payload: payload,
});

export const getStudentDashboardDataSuccess = (payload) => ({
  type: actions.GET_STUDENT_DASHBOARD_SUCCESS,
  payload: payload,
});

export const getViewAllAssignmentsData = (payload) => ({
  type: actions.GET_VIEW_All_ASSIGNMENTS,
  payload: payload,
});

export const getViewAllAssignmentsDataSuccess = (payload) => ({
  type: actions.GET_VIEW_All_ASSIGNMENTS_SUCCESS,
  payload: payload,
});

export const getAssignmentResponseData = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESPONSES,
  payload: payload,
});

export const getAssignmentResponseDataSuccess = (payload) => ({
  type: actions.GET_ASSIGNMENT_RESPONSES_SUCCESS,
  payload: payload,
});

export const getOtherStudentsTrackingData = (payload) => ({
  type: actions.GET_OTHER_STUDENTS_TRACKING,
  payload: payload,
});

export const getOtherStudentsTrackingDataSuccess = (payload) => ({
  type: actions.GET_OTHER_STUDENTS_TRACKING_SUCCESS,
  payload: payload,
});

export const getTrackLessonData = (payload) => ({
  type: actions.GET_TRACK_LESSON,
  payload: payload,
});

export const getTrackLessonDataSuccess = (payload) => ({
  type: actions.GET_TRACK_LESSON_SUCCESS,
  payload: payload,
});

export const getTrackLessonResourceData = (payload) => ({
  type: actions.GET_TRACK_LESSON_RESOURCE,
  payload: payload,
});

export const getTrackLessonResourceDataSuccess = (payload) => ({
  type: actions.GET_TRACK_LESSON_RESOURCE_SUCCESS,
  payload: payload,
});

export const getQuizDetailsData = (payload) => ({
  type: actions.GET_QUIZ_DETAILS_DATA,
  payload: payload,
});
export const getinvokeArrayOfSagas = (payload) => ({
  type: actions.GET_TRACK_LESSON_AND_RESOURCE,
  payload: payload,
});
export const getInvokeArrayAssignShareResponse = (payload) => ({
  type: actions.GET_TRACK_ASSIGN_SHARE_AND_RESPONSE,
  payload: payload,
});

export const getQuizDetailsDataSuccess = (payload) => ({
  type: actions.GET_QUIZ_DETAILS_DATA_SUCCESS,
  payload: payload,
});

export const getStartQuizData = (payload) => ({
  type: actions.GET_START_QUIZ_DATA,
  payload: payload,
});

export const getStartQuizDataSuccess = (payload) => ({
  type: actions.GET_START_QUIZ_DATA_SUCCESS,
  payload: payload,
});

export const getItemPrintQuizData = (payload) => ({
  type: actions.GET_ITEM_PRINT_QUIZ_DATA,
  payload: payload,
});

export const getItemPrintQuizDataSuccess = (payload) => ({
  type: actions.GET_ITEM_PRINT_QUIZ_DATA_SUCCESS,
  payload: payload,
})

export const getSubmitQuizData = (payload) => ({
  type: actions.GET_SUBMIT_QUIZ_DATA,
  payload: payload,
});

export const getSubmitQuizDataSuccess = (payload) => ({
  type: actions.GET_SUBMIT_QUIZ_DATA_SUCCESS,
  payload: payload,
});

export const getStartLearnosityAssessment = (payload) => ({
  type: actions.GET_START_LEARNOSITY_ASSESSMENT,
  payload: payload,
});

export const getStartLearnosityAssessmentSuccess = (payload) => ({
  type: actions.GET_START_LEARNOSITY_ASSESSMENT_SUCCESS,
  payload: payload,
});

export const submitLearnosityAssessment = (payload) => ({
  type: actions.SUBMIT_LEARNOSITY_ASSESSMENT,
  payload: payload,
});

export const submitLearnosityAssessmentSuccess = (payload) => ({
  type: actions.SUBMIT_LEARNOSITY_ASSESSMENT_SUCCESS,
  payload: payload,
});
export const getAssignmentFromLessonUrl = (payload) => ({
  type: actions.GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL,
  payload: payload,
});

export const getAssignmentFromLessonUrlSuccess = (payload) => ({
  type: actions.GET_ASSIGN_ASSIGNMENT_FROM_LESSONURL_SUCCESS,
  payload: payload,
});
export const getCheckResourceCompletion = (payload) => ({
  type: actions.GET_CHECK_RESOURCE_COMPLETION,
  payload: payload,
});
export const getCheckResourceCompletionSuccess = (payload) => ({
  type: actions.GET_CHECK_RESOURCE_COMPLETION_SUCCESS,
  payload: payload,
});
export const getNextActivityData = (payload) => ({
  type: actions.GET_NEXT_ACTIVITY_DATA,
  payload: payload,
});
export const getNextActivityDataSuccess = (payload) => ({
  type: actions.GET_NEXT_ACTIVITY_DATA_SUCCESS,
  payload: payload,
});

