import React from "react";
import { WorkspaceContextConsumer } from "contexts/workspace";

export default function withWorkspaceContext(WrappedComponent) {
  class C extends React.Component {
    render() {
      return (
        <WorkspaceContextConsumer>
          {WorkspaceContext => (
            <WrappedComponent
              {...this.props}
              WorkspaceContext={WorkspaceContext}         //For any child component the context state will be availible as props
            />
          )}
        </WorkspaceContextConsumer>
      );
    }
  }
  C.displayName = `withWorkspaceContext(${WrappedComponent.displayName ||
    WrappedComponent.name})`;
  return C;
}
